function formatDateToText(dateString: string | Date): string {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    throw new Error(
      "Fecha inválida. Asegúrate de usar un formato válido como 'YYYY-MM-DD' o 'MM/DD/YYYY'.",
    );
  }

  const months = [
    'ene.',
    'feb.',
    'mar.',
    'abr.',
    'may.',
    'jun.',
    'jul.',
    'ago.',
    'sep.',
    'oct.',
    'nov.',
    'dic.',
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} de ${month} de ${year}`;
}

export default formatDateToText;
