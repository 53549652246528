import { FC, useEffect, useMemo, useRef } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import { StayDataType } from "data/types";
import useNcId from "hooks/useNcId";
import StayCard from "components/StayCard/StayCard";
import useRecentProperties from "hooks/users/useRecentProperties";

export interface SectionRecentlyViewedProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  itemPerRow?: 4 | 5;
  uniqueClassName: string;
  data: StayDataType[];
}

const SectionRecentlyViewed: FC<SectionRecentlyViewedProps> = ({
  heading = "Recently Viewed",
  subHeading = "You’ve recently checked",
  className = "",
  itemPerRow = 5,
  uniqueClassName,
  data,
}) => {
  const sliderRef = useRef<HTMLDivElement | null>(null); // Referencia al contenedor del slider

  const UNIQUE_CLASS =
    "SectionSliderNewCategories__" + uniqueClassName + useNcId();

  const MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: itemPerRow,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: itemPerRow - 1,
        },
        1024: {
          gap: 20,
          perView: itemPerRow - 1,
        },
        768: {
          gap: 20,
          perView: itemPerRow - 2,
        },
        640: {
          gap: 20,
          perView: itemPerRow - 3,
        },
        500: {
          gap: 20,
          perView: 1.3,
        },
      },
    });
  }, [UNIQUE_CLASS, itemPerRow]);

  // Montar Glide solo cuando data esté lista y el contenedor exista
  useEffect(() => {
    if (data && data.length > 0 && sliderRef.current) {
      const glideNode = sliderRef.current.querySelector(`.${UNIQUE_CLASS}`);

      if (glideNode) {
        setTimeout(() => {
          MY_GLIDEJS.mount();
        }, 100);
      }
    }
  }, [data, MY_GLIDEJS, UNIQUE_CLASS]);

  if (!data || data.length === 0) {
    return <div>No recently viewed properties found.</div>;
  }

  return (
    <div ref={sliderRef} className={`${className} ${UNIQUE_CLASS}`}>
      <Heading desc={subHeading}>{heading}</Heading>

      <div className="glide__track" data-glide-el="track">
        <div
          className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4`}
        >
          {data.map((property: StayDataType) => (
            <StayCard data={property} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SectionRecentlyViewed;
