import DatePickerCustomDay from "components/DatePickerCustomDay";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import React, { FC, useState } from "react";
import DatePicker from "react-datepicker";
import CommonLayout from "./CommonLayout";
import useAddListingStore from "stores/addListinigStore";
import Input from "shared/Input/Input";
import FormItem from "./FormItem";
import { FiLink } from "react-icons/fi";
import useAirbnbCalendar from "hooks/useAirbnbCalendar";
import { FiLoader } from "react-icons/fi";

export interface PageAddListing9Props {}

const PageAddListing9: FC<PageAddListing9Props> = () => {
  const { maxNights, minNights, setProperty } = useAddListingStore();
  const [dates, setDates] = useState<number[]>([
    new Date("2023/02/06").getTime(),
    new Date("2023/02/09").getTime(),
    new Date("2023/02/15").getTime(),
  ]);

  const [airbnbUrl, setAirbnbUrl] = useState("");
  const [blockedDatesSuccess, setBlockedDatesSuccess] =
    useState<Boolean>(false);
  const { airbnbCalendar, isLoading, isError } = useAirbnbCalendar();

  const getCalendarFromAirbnb = async () => {
    console.log(dates);
    try {
      const result = await airbnbCalendar({
        calendarUrl: airbnbUrl,
      });
      console.log(result);
      const newBlockedDates = new Set(dates);

      result.unavailableDates.forEach(({ startDate, endDate }) => {
        let currentDate = new Date(startDate);
        const lastDate = new Date(endDate);

        while (currentDate <= lastDate) {
          newBlockedDates.add(currentDate.getTime());
          currentDate = new Date(
            currentDate.setDate(currentDate.getDate() + 1)
          );
        }
      });
      setDates(Array.from(newBlockedDates).sort((a, b) => a - b));
      if (!isError && !isLoading) {
        setBlockedDatesSuccess(true);
      }
      console.log(dates);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <CommonLayout
      index="09"
      backtHref="/add-listing-8"
      nextHref="/add-listing-10"
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">How long can guests stay?</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {` Shorter trips can mean more reservations, but you'll turn over your
          space more often.`}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-7">
          {/* ITEM */}
          <NcInputNumber
            onChange={(e) => setProperty("minNights", e)}
            label="Nights min"
            defaultValue={minNights}
          />
          <NcInputNumber
            onChange={(e) => setProperty("maxNights", e)}
            label="Nights max"
            defaultValue={maxNights}
          />
          <FormItem
            label="Airbnb calendar"
            desc="Add your URL calendar from Airbnb to connect the calendar"
          >
            <div className="flex items-center space-x-2">
              <Input
                placeholder="https://www.airbnb.com/calendar/ical/"
                className="flex-grow"
                onChange={(e) => setAirbnbUrl(e.target.value)}
              />
              <button
                onClick={() => getCalendarFromAirbnb()}
                className="flex items-center justify-center w-10 h-10 bg-primary-100 text-white rounded-full hover:bg-primary-200 transition-colors"
              >
                {isLoading ? (
                  <FiLoader className="w-4 h-4" />
                ) : (
                  <FiLink className="w-4 h-4" />
                )}
              </button>
            </div>
          </FormItem>
          {isError && <span className="text-red-500">Error</span>}
          {blockedDatesSuccess && (
            <span className="text-green-500">
              calendar connected successfully
            </span>
          )}
        </div>

        {/*  */}
        <div>
          <h2 className="text-2xl font-semibold">Set your availability</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Editing your calendar is easy—just select a date to block or unblock
            it. You can always make changes after you publish.
          </span>
        </div>

        <div className="addListingDatePickerExclude">
          <DatePicker
            onChange={(date) => {
              let newDates = [];

              if (!date) {
                return;
              }
              const newTime = date.getTime();
              if (dates.includes(newTime)) {
                newDates = dates.filter((item) => item !== newTime);
              } else {
                newDates = [...dates, newTime];
              }
              setDates(newDates);
            }}
            selected={new Date()}
            monthsShown={2}
            showPopperArrow={false}
            excludeDates={dates.filter(Boolean).map((item) => new Date(item))}
            inline
            renderCustomHeader={(p) => (
              <DatePickerCustomHeaderTwoMonth {...p} />
            )}
            renderDayContents={(day, date) => (
              <DatePickerCustomDay dayOfMonth={day} date={date} />
            )}
          />
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing9;
