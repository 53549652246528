import { Tab } from "@headlessui/react";
import CommentListing from "components/CommentListing/CommentListing";
import BookingStayCard from "components/BookingStayCard/BookingStayCard";
import { FC, Fragment, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Helmet } from "react-helmet";
import useUserStore from "stores/userStore";
import { PhoneIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import useGetReservation from "hooks/reservation/useGetReservation";

export interface AuthorPageProps {
  className?: string;
}

const AuthorPage: FC<AuthorPageProps> = ({ className = "" }) => {
  let [categories] = useState(["Upcoming", "Past"]);
  const { isLogged, user } = useUserStore();
  const { useGetUserReservations } = useGetReservation();
  const { data: reservations } = useGetUserReservations(user.user_id);

  const upcomingReservations = reservations?.filter(
    (reservation) =>
      reservation.status === "pending" ||
      reservation.status === "confirmed" ||
      reservation.status === "paid" ||
      reservation.status === "check_in" ||
      reservation.status === "in_progress" ||
      reservation.status === "preauthorized"
  );

  const pastReservations = reservations?.filter(
    (reservation) =>
      reservation.status === "canceled" ||
      reservation.status === "completed" ||
      reservation.status === "refunded" ||
      reservation.status === "no_show" ||
      reservation.status === "disputed" ||
      reservation.status === "rejected" ||
      reservation.status === "failed"
  );

  const renderSidebar = () => {
    return (
      <div className=" w-full flex flex-col items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
        <Avatar
          hasChecked
          hasCheckedClass="w-6 h-6 -top-0.5 right-2"
          sizeClass="w-28 h-28"
          imgUrl={isLogged ? user.profile_picture : ""}
        />

        {/* ---- */}
        <div className="space-y-3 text-center flex flex-col items-center">
          <h2 className="text-3xl font-semibold">
            {isLogged ? user.name : "Kevin Francis"}
          </h2>
        </div>
        {/* ---- */}
        <div className="border-b border-neutral-200 dark:border-neutral-700 w-14"></div>

        {/* ---- */}
        <div className="space-y-4">
          <div className="flex items-center space-x-4">
            <PhoneIcon className="h-6 w-6 text-neutral-400" />
            <span className="text-neutral-6000 dark:text-neutral-300">
              {isLogged ? user.phone : "+1 234 567 890"}
            </span>
          </div>
          <div className="flex items-center space-x-4">
            <EnvelopeIcon className="h-6 w-6 text-neutral-400" />
            <span className="text-neutral-6000 dark:text-neutral-300">
              {isLogged ? user.email : ""}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Your Bookings</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {reservations?.length} bookings
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              {categories.map((item) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? "bg-secondary-900 text-secondary-50 "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {upcomingReservations?.map((Booking) => (
                    <BookingStayCard key={Booking.property_id} data={Booking} />
                  ))}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {pastReservations?.map((Booking) => (
                    <BookingStayCard key={Booking.property_id} data={Booking} />
                  ))}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          <CommentListing hasListingTitle className="pb-8" />
          <CommentListing hasListingTitle className="py-8" />
          <CommentListing hasListingTitle className="py-8" />
          <CommentListing hasListingTitle className="py-8" />
          <div className="pt-8">
            <ButtonSecondary>View more 20 reviews</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
      <Helmet>
        <title>Bookings || PVR PROPERTIES</title>
      </Helmet>
      <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
        <div className="block flex-grow mb-24 lg:mb-0">
          <div className="lg:sticky lg:top-24">{renderSidebar()}</div>
        </div>
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
          {renderSection1()}
          {renderSection2()}
        </div>
      </main>
    </div>
  );
};

export default AuthorPage;
