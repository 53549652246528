import { useQuery } from "@tanstack/react-query";
import { Reservation, UserReservation } from "data/types";
import ApiClient from "services/apiClient";

const apiClient = new ApiClient<Reservation>("/reservation");
const apiClientUser = new ApiClient<UserReservation[]>("/reservations");

const useGetReservation = () => {
  const useGetReservationById = (id: string) => {
    return useQuery<Reservation, Error>(
      ["reservation", id],
      () => apiClient.get(id),
      {
        enabled: !!id,
        staleTime: 1000 * 60 * 5,
      }
    );
  };

  const useGetUserReservations = (user_id: number) => {
    return useQuery<UserReservation[], Error>(
      ["reservations", user_id],
      () => apiClientUser.get(user_id),
      {
        enabled: !!user_id,
        staleTime: 1000 * 60 * 5,
      }
    );
  };

  return { useGetReservationById, useGetUserReservations };
};

export default useGetReservation;
