import { FC, ReactNode, useState } from "react";
import { StayDataType } from "data/types";
import HeaderFilter from "./HeaderFilter";
import StayCard from "components/StayCard/StayCard";
import Pagination from "shared/Pagination/Pagination";
import useGetRecommendedGeneralProperties from "hooks/properties/useGetRecommendedGeneralProperties";

//
export interface SectionGridFeaturePlacesProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  //stayListings = DEMO_DATA,
  gridClass = "",
  heading = "Featured places to stay",
  subHeading = "Popular places to stay that PVR PROPERTIES recommends for you",
  headingIsCenter,
  tabs = ["VILLA", "CONDO", "HOTEL"],
}) => {
  const [page, setPage] = useState(1);
  const pageSize = 8;
  const [category, setCategory] = useState(1);

  const { data, isLoading, error } = useGetRecommendedGeneralProperties(
    page,
    pageSize,
    category
  );

  const nextPage = (page: number) => {
    setPage(page);
    document
      .getElementById("HeaderFilter")
      ?.scrollIntoView({ behavior: "smooth" });
  };

  if (error) return <span>{error.message}</span>;

  if (isLoading) return <span>Loading...</span>;

  const handleTypeChange = (type: string) => {
    let newCategory = 0;
    setPage(1);
    switch (type) {
      case "VILLA":
        newCategory = 1;
        break;
      case "CONDO":
        newCategory = 3;
        break;
      case "CONDOMINIUM":
        newCategory = 4;
        break;
      case "HOTEL":
        newCategory = 2;
        break;
    }
    setCategory(newCategory);
  };

  const renderCard = (stay: StayDataType) => {
    return <StayCard key={stay.property_id} data={stay} />;
  };

  const NoPropertyAvaible = () => {
    return (
      <div>
        <span className="text-gray-500">
          No properties were found with those filters.
        </span>
      </div>
    );
  };

  return (
    <div id="HeaderFilter" className="nc-SectionGridFeaturePlaces relative">
      <HeaderFilter
        tabActive={"VILLA"}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={handleTypeChange}
      />
      <div
        className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
      >
        {data && data.results.length === 0 ? (
          <NoPropertyAvaible />
        ) : (
          data?.results.map((stay) => renderCard(stay))
        )}
      </div>
      <div className="flex mt-16 justify-center items-center">
        <Pagination
          currentPage={page}
          pages={data.meta.totalPage}
          onPageChange={(e) => nextPage(e)}
        />
      </div>
    </div>
  );
};

export default SectionGridFeaturePlaces;
