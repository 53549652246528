import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import useReservation, {
  CreateReservationParams,
} from "hooks/reservation/useReservation";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

interface FormCheckOutProps {
  ReservationData: CreateReservationParams;
}

const stripePromise = loadStripe(
  "pk_test_51PludnLAJoUTgXoBZdj1McJ65G9DxQ998rVdgzExhJVn7KTwBxgjqwOAYmB6WCCDhAc2sGk0N0NDSjK9Z8FoWz9y00wZMujuhk"
);

const FormCheckOut = ({ ReservationData }: FormCheckOutProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const onError = () => {
    console.log("Error");
  };
  const onSuccess = () => {
    console.log("Success");
  };
  const { createReservation } = useReservation(onSuccess, onError);

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (!stripe || !elements) {
      Swal.fire({
        title: "Error",
        text: "Stripe is not loaded yet. Please try again later.",
        icon: "error",
      });
      return;
    }

    createReservation.mutate(ReservationData, {
      onSuccess: async (data) => {
        if (data.client_secret) {
          const cardElement = elements.getElement(CardElement);

          const { error, paymentIntent } = await stripe.confirmCardPayment(
            data.client_secret,
            {
              payment_method: {
                card: cardElement!,
              },
            }
          );

          if (error) {
            console.error("Error confirmando el pago:", error);
            alert(`Error procesando el pago: ${error.message}`);
            return;
          }

          if (paymentIntent && paymentIntent.status === "requires_capture") {
            navigate(
              `/pay-done/${data.reservation_id}?status=success&PropertyId=${ReservationData.property_id}`
            );
          }
        }
      },
      onError: (error) => {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
        });
      },
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="flex flex-row">
        <div className="w-full mr-2">
          <Label>Name</Label>
          <Input
            placeholder="Card Name"
            className="h-11"
            name="firstName"
            type="text"
          />
        </div>
        <div className="">
          <Label>Zip Code</Label>
          <Input
            placeholder="Zip Code"
            className="h-11"
            name="ZipCode"
            type="text"
          />
        </div>
      </div>
      <div className="space-y-4">
        <Label>Card Details</Label>
        <div className="p-2 border border-neutral-300 dark:border-neutral-600 rounded-md">
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#424770",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
        </div>
      </div>
      {/* Politicas de cancelacion */}

      <div className="w-full border-b border-neutral-200 dark:border-neutral-700 my-5"></div>
      <h4 className="text-md font-semibold">Cancellation Policies</h4>
      <p
        style={{
          fontSize: "10px",
        }}
      >
        If a cancellation is made <span className="font-semibold">90 days</span>{" "}
        or more prior to the scheduled date, a full refund of the amount paid
        will be issued. <br />
        For cancellations made{" "}
        <span className="font-semibold">between 60 and 89 days</span> prior to
        the scheduled date, <span className="font-semibold">50%</span> of the
        amount paid will be refunded. <br />
        For cancellations made less than{" "}
        <span className="font-semibold">60 days</span> prior to the scheduled
        date, no refund will be issued.
      </p>
      <div className="w-full border-b border-neutral-200 dark:border-neutral-700 my-5"></div>
      <div className="flex flex-row py-2 ">
        <div className="me-6 my-auto  ">
          <CalendarDaysIcon className="w-8 h-8 text-red-500 dark:text-red-600" />
        </div>
        <div className="text-sm">
          <p>
            We will not confirm your reservation until the host accepts the
            request (within 24 hours). You will not be charged until then.
          </p>
        </div>
      </div>
      <div className="w-full border-b border-neutral-200 dark:border-neutral-700 my-5"></div>
      <div className="mb-2">
        <h4 className="text-sm font-semibold ">
          Agreement to Policies and Rules
        </h4>
      </div>
      <span
        className="mt-2 text-sm"
        style={{
          fontSize: "10px",
        }}
      >
        By selecting the button below, you confirm that you have read,
        understood, and agree to comply with PVR Properties' policies and rules,
        including cancellation policies and house rules. Your acceptance serves
        as acknowledgment of these terms as part of your reservation process.{" "}
        <br />{" "}
        <span className="font-semibold">
          Please ensure you have reviewed all policies carefully before
          proceeding.
        </span>
      </span>
      <ButtonPrimary
        type="submit"
        className="w-full"
        disabled={!stripe || createReservation.isLoading}
      >
        {createReservation.isLoading ? "Procesando..." : "Confirm your booking"}
      </ButtonPrimary>
    </form>
  );
};

const StripeWrapper = ({ ReservationData }: FormCheckOutProps) => (
  <Elements stripe={stripePromise}>
    <FormCheckOut ReservationData={ReservationData} />
  </Elements>
);

export default StripeWrapper;
