"use client";
import { FC, useEffect, useState } from "react";
import StaySearchForm from "./(stay-search-form)/StaySearchForm";
import RealEstateSearchForm from "./(real-estate-search-form)/RealEstateSearchForm";
import useFilterStore from "stores/FilterStore";
// import ExperiencesSearchForm from "./(experiences-search-form)/ExperiencesSearchForm";
// import RentalCarSearchForm from "./(car-search-form)/RentalCarSearchForm";
// import FlightSearchForm from "./(flight-search-form)/FlightSearchForm";

export type SearchTab =
  | "Rentals"
  | "Experiences"
  | "Cars"
  | "Flights"
  | "Stays"
  | "Sales";
// ";

export interface HeroSearchFormProps {
  className?: string;
  currentTab?: SearchTab;
  isLocationOpen?: boolean;
  currentPage?:
    | "Stays"
    | "Experiences"
    | "Cars"
    | "Flights"
    | "Rentals"
    | "Sales";
}

const HeroSearchForm: FC<HeroSearchFormProps> = ({
  className = "",
  currentTab = "Rentals",
  isLocationOpen,
  currentPage,
}) => {
  const tabs: SearchTab[] = ["Rentals", "Sales"];
  const [tabActive, setTabActive] = useState<SearchTab>(currentTab);
  const { setFilter, type } = useFilterStore();

  useEffect(() => {
    const currentTabByType = type === 0 ? "Rentals" : "Sales";
    setTabActive(currentTabByType);
  }, [type]);

  const renderTab = () => {
    return (
      <ul className="ml-2 sm:ml-6 md:ml-12 flex space-x-5 sm:space-x-8 lg:space-x-11 overflow-x-auto hiddenScrollbar">
        {tabs.map((tab) => {
          const active = tab === tabActive;
          return (
            <li
              onClick={() => {
                setTabActive(tab);
                setFilter("type", tab === "Rentals" ? 0 : 1);
              }}
              className={`flex-shrink-0 flex items-center cursor-pointer text-sm lg:text-base font-medium ${
                active
                  ? ""
                  : "text-neutral-500 hover:text-neutral-700 dark:hover:text-neutral-400"
              } `}
              key={tab}
            >
              {active && (
                <span className="block w-2.5 h-2.5 rounded-full bg-neutral-800 dark:bg-neutral-100 mr-2" />
              )}
              <span>{tab}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderForm = () => {
    switch (tabActive) {
      case "Rentals":
        return <StaySearchForm isLocationOpen={isLocationOpen} />;
      case "Sales":
        return <RealEstateSearchForm />;
      // case "Cars":
      //   return <RentalCarSearchForm />;
      // case "Flights":
      //   return <FlightSearchForm />;

      default:
        return null;
    }
  };

  return (
    <div
      className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}
    >
      {renderTab()}
      {renderForm()}
    </div>
  );
};

export default HeroSearchForm;
