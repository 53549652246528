import { useQuery, useQueryClient } from "@tanstack/react-query";
import { StayDataType } from "data/types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ApiClient from "services/apiClient";
import useUserStore from "stores/userStore";

export interface PropertiesResponse {
  results: StayDataType[];
  meta: {
    totalProperties: number;
    totalPage: number;
    currentPage: number;
  };
}

const apiClient = new ApiClient<PropertiesResponse>(
  "/recommendedPropertiesByLastSearch"
);

const useGetRecommendedProperties = (page: number, pageSize: number) => {
  const { user } = useUserStore();
  const session_id = user?.user_id
    ? null
    : localStorage.getItem("session_id") || null;
  // const { type } = useFilterStore();
  const location = useLocation();
  const queryClient = useQueryClient();
  const query = useQuery<PropertiesResponse, Error>({
    queryKey: [
      "RecommendedProperties",
      user?.user_id || session_id,
      page,
      pageSize,
      user.currency,
    ],
    queryFn: () =>
      apiClient.getAllWithParams({
        params: {
          user_id: user?.user_id || null,
          session_id: session_id,
          page: page,
          pageSize: pageSize,
          currency: user?.currency || "USD",
        },
      }),
    staleTime: 1000 * 60 * 5,
    keepPreviousData: true,
  });

  useEffect(() => {
    queryClient.invalidateQueries([
      "RecommendedProperties",
      user?.user_id || session_id,
    ]);
  }, [location.pathname, user?.user_id, session_id, queryClient]);

  return query;
};

export default useGetRecommendedProperties;
