import { useQuery } from "@tanstack/react-query";
import { StayDataType } from "data/types";
import ApiClient from "services/apiClient";
import useUserStore from "stores/userStore";

interface PropertiesResponse {
  results: StayDataType[];
  meta: {
    totalProperties: number;
    totalPage: number;
    currentPage: number;
  };
}

const apiClient = new ApiClient<PropertiesResponse>("/generalRecommended");

const useGetRecommendedGeneralProperties = (
  page: number,
  pageSize: number,
  category: number
) => {
  const { user } = useUserStore();
  return useQuery<PropertiesResponse, Error>({
    queryKey: [
      "RecommendedProperties",
      page,
      pageSize,
      user.currency,
      category,
    ],
    queryFn: () =>
      apiClient.getAllWithParams({
        params: {
          page: page,
          pageSize: pageSize,
          currency: user?.currency || "USD",
          category: category,
        },
      }),
    staleTime: 1000 * 60 * 5,
    keepPreviousData: true,
  });
};

export default useGetRecommendedGeneralProperties;
