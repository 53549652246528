import { Coords } from "google-map-react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

interface PropertiesQuery {
  address?: string;
  zone?: string;
  city?: string;
  state?: string;
  country?: string;
  type?: string;
  category?: string;
  adults?: number;
  children?: number;
  infants?: number;
  tags?: string;
  beds?: number;
  bedrooms?: number;
  bathrooms?: number;
  lng?: string;
  lat?: string;
}

const useGetQueryParams = (): PropertiesQuery => {
  const { search } = useLocation();
  const [query, setQuery] = useState<PropertiesQuery>({});

  useEffect(() => {
    const params = new URLSearchParams(search);

    const parsedQuery: PropertiesQuery = {
      address: params.get("address") ?? undefined,
      zone: params.get("zone") ?? undefined,
      city: params.get("city") ?? undefined,
      lng: params.get("lng") ?? undefined,
      lat: params.get("lat") ?? undefined,
      state: params.get("state") ?? undefined,
      category: params.get("category") ?? undefined,
      country: params.get("country") ?? undefined,
      type: params.get("type") ?? undefined,
      adults: params.get("adults") ? Number(params.get("adults")) : undefined,
      children: params.get("children")
        ? Number(params.get("children"))
        : undefined,
      infants: params.get("infants")
        ? Number(params.get("infants"))
        : undefined,
      tags: params.get("tags") ?? undefined,
      beds: params.get("beds") ? Number(params.get("beds")) : undefined,
      bedrooms: params.get("bedrooms")
        ? Number(params.get("bedrooms"))
        : undefined,
      bathrooms: params.get("bathrooms")
        ? Number(params.get("bathrooms"))
        : undefined,
    };

    setQuery(parsedQuery);
  }, [search]);

  return query;
};

export default useGetQueryParams;
