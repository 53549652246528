import { Tab } from "@headlessui/react";
import StayCard from "components/StayCard/StayCard";
import { Fragment, useState } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";
import useWislistPropertiesUser from "hooks/properties/useWishlistPropertiesUser";
import useUserStore from "stores/userStore";

const AccountSavelists = () => {
  let [categories] = useState(["Rentals"]);

  const { user } = useUserStore();
  const [page] = useState(1);
  const [pageSize] = useState(8);
  const { data, isLoading, error } = useWislistPropertiesUser(
    page,
    pageSize,
    user.user_id
  );

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>An error occurred: {error.message}</div>;

  const NoPropertyAvaible = () => {
    return (
      <div>
        <span className="text-gray-500">
          There are no properties in your wishlist yet—start adding some now!
        </span>
      </div>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="space-y-6 sm:space-y-8">
        <div>
          <h2 className="text-3xl font-semibold">Save lists</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              {categories.map((item) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? "bg-secondary-900 text-secondary-50 "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="mt-8">
                {data.results.length === 0 ? (
                  <NoPropertyAvaible />
                ) : (
                  <>
                    <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                      {data.results.map((stay) => (
                        <StayCard key={stay.property_id} data={stay} />
                      ))}
                    </div>
                    <div className="flex mt-11 justify-center items-center">
                      <ButtonSecondary>Show me more</ButtonSecondary>
                    </div>
                  </>
                )}
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  return (
    <div>
      <CommonLayout>{renderSection1()}</CommonLayout>
    </div>
  );
};

export default AccountSavelists;
