import ApiClient from "./apiClient";

export interface UserData {
  user_id: number;
  phone: string;
  email: string;
  name: string;
  password: string;
  profile_picture: string;
  token: string;
}

const service = new ApiClient<UserData>("/user");

export default service;
