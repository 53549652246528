import React, { createContext, useContext } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";

const GoogleAuthContext = createContext({});

interface Props {
  children: React.ReactNode;
}

export const GoogleAuthProviderWrapper: React.FC<Props> = ({ children }) => {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  if (!clientId) {
    console.error(
      "El Google Client ID no está configurado. Asegúrate de agregarlo en tu archivo .env como REACT_APP_GOOGLE_CLIENT_ID."
    );
    return null;
  }
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
      {children}
    </GoogleOAuthProvider>
  );
};

export const useGoogleAuth = () => useContext(GoogleAuthContext);
