import { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { UserReservation } from "data/types";
import { Link } from "react-router-dom";
import Badge from "shared/Badge/Badge";
import formatDateToText from "utils/formatDateToText";

export interface StayCardProps {
  className?: string;
  data: UserReservation;
  size?: "default" | "small";
}

const StayCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  data,
}) => {
  const { images, address, title, property_id } = data.property;

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`StayCard_${property_id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={images}
          href={`/listing-stay-detail/${title}/${property_id}`}
        />
        <Badge
          name={data.payment_status}
          color="green"
          className="absolute top-2 left-2"
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <Badge
            name={data.status}
            color={
              data.status === "pending"
                ? "yellow"
                : data.status === "confirmed"
                ? "green"
                : data.status === "paid"
                ? "green"
                : data.status === "check_in"
                ? "green"
                : data.status === "in_progress"
                ? "green"
                : data.status === "preauthorized"
                ? "yellow"
                : data.status === "canceled"
                ? "red"
                : data.status === "completed"
                ? "green"
                : data.status === "refunded"
                ? "red"
                : data.status === "no_show"
                ? "red"
                : data.status === "disputed"
                ? "red"
                : data.status === "rejected"
                ? "red"
                : data.status === "failed"
                ? "red"
                : "gray"
            }
          />
          <div className="flex items-center space-x-2">
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="">{address}</span>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            {formatDateToText(data.start_date)} -{" "}
            {formatDateToText(data.end_date)}
          </span>
          {/* {!!reviewStart && (
            <StartRating reviewCount={reviewCount} point={reviewStart} />
          )} */}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      <Link to={`/listing-stay-detail/${title}/${property_id}`}>
        {renderContent()}
      </Link>
    </div>
  );
};

export default StayCard;
