import {
  ClockIcon,
  MapPinIcon,
  ArrowTrendingUpIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";
import { useState, useRef, useEffect, FC } from "react";
import ClearDataButton from "./ClearDataButton";
import FilterStore from "stores/FilterStore";
import usePlacesFind, { LocalLocation } from "hooks/usePlacesFind";
import useNameFind from "hooks/useNameFind";
import { useNavigate } from "react-router-dom";
import { locationRecommend } from "data/types";

export interface LocationInputProps {
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
}

const LocationInput: FC<LocationInputProps> = ({
  autoFocus = false,
  placeHolder = "Location Or Name",
  desc = "Where are you going?",
  className = "nc-flex-1.5",
  divHideVerticalLineClass = "left-10 -right-0.5",
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const [showPopover, setShowPopover] = useState(autoFocus);

  const { value, setValue, data, isLoading } = usePlacesFind();
  const { setValue: setValueName, data: Names } = useNameFind();

  const { setFilter, setCoords } = FilterStore();

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (data && data.length === 0 && !isLoading) {
      setValueName(value);
    }
  }, [data]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  const handleSelectLocation = async (item: LocalLocation) => {
    console.log(item);
    setValue(item.address);
    setShowPopover(false);
    setFilter("address", item.address);
    setFilter("state", item.state);
    setFilter("city", item.city);
    setFilter("country", item.country);
    setCoords(item.coords);
  };

  const renderRecentSearches = () => {
    const recommendedDestinations: locationRecommend[] = [
      {
        address: "Nuevo Vallarta, Nayarit, Mexico",
        city: "Nuevo Vallarta",
        country: "Mexico",
        state: "Nayarit",
        type: "recommend",
        coords: {
          lat: 20.6969,
          lng: -105.2949,
        },
      },
      {
        address: "Punta Mita, Nayarit, Mexico",
        city: "Punta Mita",
        country: "Mexico",
        state: "Nayarit",
        type: "recommend",
        coords: {
          lat: 20.7714,
          lng: -105.5185,
        },
      },
      {
        address: "Sayulita, Nayarit, Mexico",
        city: "Sayulita",
        country: "Mexico",
        state: "Nayarit",
        type: "recommend",
        coords: {
          lat: 20.8686,
          lng: -105.4404,
        },
      },
      {
        address: "San Pancho, Nayarit, Mexico",
        city: "San Pancho",
        country: "Mexico",
        state: "Nayarit",
        type: "recommend",
        coords: {
          lat: 20.9016,
          lng: -105.4184,
        },
      },
      {
        address: "Careyes, Jalisco, Mexico",
        city: "Careyes",
        country: "Mexico",
        state: "Jalisco",
        type: "recommend",
        coords: {
          lat: 19.4683,
          lng: -105.0444,
        },
      },
    ];

    const recentSearches: locationRecommend[] = JSON.parse(
      localStorage.getItem("recentSearches") || "[]"
    ).map((search: LocalLocation) => ({
      address: search.address,
      city: search.city,
      state: search.state,
      country: search.country,
      coords: search.coords,
      type: "recent",
    }));

    const filteredRecommended = recommendedDestinations.filter(
      (item) =>
        !recentSearches.some(
          (search) =>
            search.city === item.city ||
            search.address.split(",")[0] === item.address.split(",")[0]
        )
    );

    const combinedDestinations = [
      ...recentSearches,
      ...filteredRecommended.filter((item) => !recentSearches.includes(item)),
    ].slice(0, 5);
    return (
      <>
        <h3 className="block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base sm:text-lg text-neutral-800 dark:text-neutral-100">
          Find Your Destinations
        </h3>
        <div className="mt-2">
          {combinedDestinations.map((item) => (
            <span
              onClick={() => handleSelectLocation(item)}
              key={item.address}
              className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
            >
              <span className="block text-neutral-400">
                {item.type === "recommend" ? (
                  <ArrowTrendingUpIcon className="h-4 sm:h-6 w-4 sm:w-6" />
                ) : (
                  <ClockIcon className="h-4 sm:h-6 w-4 sm:w-6" />
                )}
              </span>
              <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                {item.address}
              </span>
            </span>
          ))}
        </div>
      </>
    );
  };

  const renderSearchValue = () => {
    return (
      <>
        {data &&
          data.map((place) => (
            <span
              onClick={() =>
                handleSelectLocation({
                  address: place.address,
                  city: place.city,
                  coords: place.coords,
                  country: place.country,
                  state: place.state,
                })
              }
              key={place.address}
              className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
            >
              <span className="block text-neutral-400">
                <MapPinIcon className="h-4 w-4 sm:h-6 sm:w-6" />
              </span>
              <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                {`${place.address}`}
              </span>
            </span>
          ))}
        {isLoading && (
          <span className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 cursor-pointer">
            <span className="block text-neutral-400">
              <ClockIcon className="h-4 w-4 sm:h-6 sm:w-6" />
            </span>
            <span className="block font-medium text-neutral-700 dark:text-neutral-200">
              Loading...
            </span>
          </span>
        )}
      </>
    );
  };

  const renderRecentSearchesNames = () => {
    return (
      <>
        {Names &&
          Names.map((name) => (
            <span
              onClick={() =>
                navigate(
                  `/listing-stay-detail/${name.title}/${name.property_id}`
                )
              }
              className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
            >
              <span className="block text-neutral-400">
                <HomeIcon className="h-4 w-4 sm:h-6 sm:w-6" />
              </span>
              <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                {`${name.title}`}
              </span>
            </span>
          ))}
        {isLoading && (
          <span className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 cursor-pointer">
            <span className="block text-neutral-400">
              <ClockIcon className="h-4 w-4 sm:h-6 sm:w-6" />
            </span>
            <span className="block font-medium text-neutral-700 dark:text-neutral-200">
              Loading...
            </span>
          </span>
        )}
      </>
    );
  };

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
          showPopover ? "nc-hero-field-focused" : ""
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow">
          <input
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            placeholder={placeHolder}
            value={value}
            autoFocus={showPopover}
            onChange={(e) => {
              setValue(e.currentTarget.value);
            }}
            ref={inputRef}
          />
          <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span className="line-clamp-1">{!!value ? placeHolder : desc}</span>
          </span>
          {value && showPopover && (
            <ClearDataButton
              onClick={() => {
                setValue("");
              }}
            />
          )}
        </div>
      </div>

      {showPopover && (
        <div
          className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 bg-white dark:bg-neutral-800 ${divHideVerticalLineClass}`}
        ></div>
      )}

      {showPopover && (
        <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          {value ? (
            <>
              {data && data.length > 0
                ? renderSearchValue()
                : renderRecentSearchesNames()}
            </>
          ) : (
            renderRecentSearches()
          )}
        </div>
      )}
    </div>
  );
};

export default LocationInput;
