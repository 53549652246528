import { useEffect, useState } from "react";
import useUserStore from "stores/userStore";
import useUser from "./useUser";
import useGetWishlist from "./useGetWishlist";
import { authService } from "services/authService";

const useInitializeUser = () => {
  const {
    setUser,
    setIsLogged,
    setUserWishlist,
    user: { user_id },
  } = useUserStore();

  const [userId, setUserId] = useState("");
  const {
    data: user,
    error: userError,
    isLoading: userLoading,
  } = useUser(userId);
  const {
    data: wishlist,
    error: wishlistError,
    isLoading: wishlistLoading,
  } = useGetWishlist(user_id);

  useEffect(() => {
    const token = authService.getToken();

    if (token) {
      // Decodificar el token JWT para obtener el user_id
      const userPayload = JSON.parse(atob(token.split(".")[1]));
      setUserId(userPayload.user_id);

      if (!userError && !userLoading && user) {
        // Actualizar estado global del usuario
        setUser({
          user_id: user.user_id,
          name: user.name,
          email: user.email,
          phone: user.phone,
          profile_picture: user.profile_picture,
        });
        setIsLogged(true);
      }

      if (wishlist && !wishlistLoading && !wishlistError) {
        // Actualizar la wishlist en el estado global
        const wishlistIds = wishlist.map((property) => property.property_id);
        setUserWishlist(wishlistIds);
      }
    }
  }, [user, userLoading, userError, wishlist, wishlistLoading, wishlistError]);
  return {
    isInitialized: Boolean(user && wishlist),
    isLoading: userLoading || wishlistLoading,
    error: userError || wishlistError,
  };
};

export default useInitializeUser;
