import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { APIProvider } from "@vis.gl/react-google-maps";
//
import "rc-slider/assets/index.css";
// STYLE
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";

//
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleAuthProviderWrapper } from "utils/GoogleAuthProvider";
import { initGooglePlaces } from "services/googlePlaces";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();


initGooglePlaces(process.env.REACT_APP_GOOGLE_API_KEY || "");


root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <GoogleAuthProviderWrapper>
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY || ""}>
          <App />
        </APIProvider>
      </GoogleAuthProviderWrapper>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
