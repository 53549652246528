import { useQuery } from "@tanstack/react-query";
import ApiClient from "services/apiClient";
import { UserData } from "services/UserService";

const useUser = (id: string) => {
  return useQuery<UserData, Error>({
    queryKey: ["user", id],
    queryFn: () => new ApiClient<UserData>("/user").get(id),
    staleTime: 1000 * 60 * 5,
    enabled: !!id,
  });
};

export default useUser;
