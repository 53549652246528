import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/Fondo5.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import useGeneralStore from "stores/generalStore";

export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  const [isOpenLocation, setIsOpenLocation] = useState(false);
  const { setOpenLocationModal } = useGeneralStore();

  const handleOpenLocation = () => {
    // si la pantalla es menor a 1024px se abre el modal
    if (window.innerWidth < 1024) {
      setOpenLocationModal(true);
    } else {
      setIsOpenLocation(!isOpenLocation);
    }
  };
  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="font-medium text-3xl md:text-4xl xl:text-6xl !leading-[114%] ">
            LUXURY AND COMFORT WITH <br />{" "}
            <span className="text-primary">PVR PROPERTIES</span>
          </h2>
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
            Embrace the Luxury Lifestyle: Rent Your Dream Property Today with
            PVR Properties - Where Elegance Meets Exclusive Living in Puerto
            Vallarta, Nayarit, Cancún, and Los Cabos.
          </span>
          <ButtonPrimary
            onClick={() => handleOpenLocation()}
            className="!text-base md:!text-lg"
          >
            Start your search
          </ButtonPrimary>
        </div>
        <div className="flex-grow">
          <img className="w-full" src={imagePng} alt="hero" />
        </div>
      </div>

      <div className="hidden lg:block z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
        <HeroSearchForm isLocationOpen={isOpenLocation} />
      </div>
    </div>
  );
};

export default SectionHero;
