import { useMutation } from "@tanstack/react-query";
import ApiClient from "services/apiClient";
import { authService } from "services/authService";
import { UserData } from "services/UserService";
import useUserStore from "stores/userStore";

const service = new ApiClient<UserData>("/login");

const useLogin = (onSuccessCallback: () => void) => {
  const { setUser, setIsLogged } = useUserStore();

  return useMutation({
    mutationFn: (user: UserData) => service.Post(user),
    onSuccess: (logedUser) => {
      const { token, ...userData } = logedUser;
      authService.setToken(token);

      setUser(userData);
      setIsLogged(true);

      if (onSuccessCallback) onSuccessCallback();
    },
    onError: (error) => {
      console.error("Error al iniciar sesion", error);
    },
  });
};

export default useLogin;
