import { useEffect, useState } from "react";
import useDebounce from "./useDebounce";
interface Coords {
  lat: number;
  lng: number;
}

export interface LocalLocation {
  address: string;
  city: string;
  state: string;
  country: string;
  coords: Coords;
}

const usePlacesFind = () => {
  const [value, setValue] = useState<string>("");
  const [data, setData] = useState<LocalLocation[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const debouncedValue = useDebounce(value, 500);

  const allowedZones = [
    "Puerto Vallarta",
    "Nuevo Vallarta",
    "Punta de Mita",
    "Punta Mita",
    "Sayulita",
    "San Pancho",
    "Cruz de Huanacaxtle",
    "Bucerías",
    "Careyes",
  ];

  const fetchGooglePlaces = async (input: string): Promise<LocalLocation[]> => {
    const autocompleteService = new (
      window as any
    ).google.maps.places.AutocompleteService();
    const placesService = new (window as any).google.maps.places.PlacesService(
      document.createElement("div")
    );

    return new Promise((resolve) => {
      autocompleteService.getPlacePredictions(
        {
          input,
          componentRestrictions: { country: "mx" },
        },
        async (predictions: any[], status: string) => {
          if (status !== "OK" || !predictions) {
            resolve([]);
            return;
          }

          const placesDetails = await Promise.all(
            predictions.map((place) => {
              return new Promise<LocalLocation>((res) => {
                placesService.getDetails(
                  {
                    placeId: place.place_id,
                    fields: ["address_components", "geometry"],
                  },
                  (details: any, detailsStatus: string) => {
                    if (detailsStatus !== "OK" || !details?.geometry) {
                      res(null as unknown as LocalLocation);
                      return;
                    }

                    let city = "";
                    let state = "";
                    let country = "";

                    details.address_components.forEach((comp: any) => {
                      if (comp.types.includes("locality")) {
                        city = comp.long_name;
                      } else if (
                        comp.types.includes("administrative_area_level_1")
                      ) {
                        state = comp.short_name;
                      } else if (comp.types.includes("country")) {
                        country = comp.long_name;
                      }
                    });

                    res({
                      address: place.description,
                      city,
                      state,
                      country,
                      coords: {
                        lat: details.geometry.location.lat(),
                        lng: details.geometry.location.lng(),
                      },
                    });
                  }
                );
              });
            })
          );

          const filteredPlaces = placesDetails
            .filter(
              (place) =>
                place && allowedZones.some((zone) => place.city.includes(zone))
            )
            .slice(0, 5); // Limitar a 5 resultados

          resolve(filteredPlaces);
        }
      );
    });
  };

  useEffect(() => {
    if (!debouncedValue) {
      setData([]);
      return;
    }

    setIsLoading(true);

    fetchGooglePlaces(debouncedValue)
      .then((results) => {
        setData(results);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [debouncedValue]);

  return { data, isLoading, value, setValue };
};

export default usePlacesFind;
