import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

const GoogleLoginButton: React.FC = () => {
  const handleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      console.log("Google Login Success Response:", response);

      try {
        // Verifica si el access_token existe
        if (!response.access_token) {
          console.error("No se recibió el access_token de Google.");
          return;
        }

        console.log("Access Token recibido:", response.access_token);

        // Enviar el token al backend
        const { data } = await axios.post(
          "http://localhost:4000/login/google",
          {
            access_token: response.access_token,
          }
        );

        console.log("Respuesta del backend:", data);

        // Guardar el token JWT en localStorage
        if (data.token) {
          localStorage.setItem("jwt", data.token);
          console.log("JWT guardado en localStorage:", data.token);
        } else {
          console.error("El backend no devolvió un JWT.");
        }

        // Mostrar información del usuario en la consola
        console.log("Usuario autenticado:", data.user);
      } catch (error) {
        console.error("Error al iniciar sesión con Google:", error);

        // Verifica si es un error de red o del backend
        if (axios.isAxiosError(error) && error.response) {
          console.error("Error del backend:", error.response.data);
        } else {
          console.error("Error de red o desconocido:");
        }
      }
    },
    onError: (error) => {
      console.error("Error en el flujo de Google Login:", error);
    },
  });

  return (
    <button
      onClick={() => handleLogin()}
      className="bg-blue-500 text-white px-4 py-2 rounded"
    >
      Sign in with Google
    </button>
  );
};

export default GoogleLoginButton;
