import { Coords } from "google-map-react";
import { mountStoreDevtool } from "simple-zustand-devtools";
import { create } from "zustand";

export interface Filter {
  country?: string;
  state?: string;
  city?: string;
  zone?: string;
  address?: string;
  category?: string;
  type?: number;
  tags?: number[];
  check_in?: Date;
  check_out?: Date;
  adults?: number;
  children?: number;
  infants?: number;
  max_price?: number;
  min_price?: number;
  lng: string;
  lat: string;
}

export interface InterfaceFilterStore {
  country?: string;
  state?: string;
  city?: string;
  zone?: string;
  address?: string;
  category?: number;
  type?: number;
  tags?: number[];
  check_in?: Date | null;
  check_out?: Date | null;
  adults?: number;
  children?: number;
  infants?: number;
  min_price?: number;
  max_price?: number;
  bedrooms?: number;
  bathrooms?: number;
  beds?: number;
  coords?: Coords;
  setFilter: (key: keyof InterfaceFilterStore, value: any) => void;
  resetStore: () => void;
  setTags: (tags: number[]) => void;
  setCoords: (coords: Coords) => void;
}

const getDefaultDates = () => {
  const today = new Date();
  const check_in = new Date();
  check_in.setDate(today.getDate() + 7);

  const check_out = new Date(check_in);
  check_out.setDate(check_in.getDate() + 7);

  return {
    check_in,
    check_out,
  };
};

const defaultDates = getDefaultDates();

const initialFilter: Partial<InterfaceFilterStore> = {
  country: "",
  state: "",
  city: "",
  zone: "",
  address: "",
  category: 0,
  type: 0,
  tags: [],
  check_in: defaultDates.check_in,
  check_out: defaultDates.check_out,
  adults: 0,
  children: 0,
  infants: 0,
  min_price: 0,
  max_price: 0,
  bedrooms: 0,
  bathrooms: 0,
  beds: 0,
  coords: {
    lat: 0,
    lng: 0,
  },
};

const useFilterStore = create<InterfaceFilterStore>((set) => ({
  ...initialFilter,
  setFilter: (key, value) => set((state) => ({ ...state, [key]: value })),
  setTags: (value: number[]) => set((state) => ({ tags: value })),
  setCoords: (coords: Coords) => set((state) => ({ ...state, coords })),
  resetStore: () => set(() => ({ ...initialFilter })),
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("listing", useFilterStore);
}

export default useFilterStore;
