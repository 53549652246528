import { useMutation, useQueryClient } from "@tanstack/react-query";
import ApiClient from "services/apiClient";
import { v4 as uuidv4 } from "uuid";

interface UserActivityData {
  user_id?: number;
  session_id?: string | null;
  property_id: string;
  action_type?: "visit";
}

// API Client para enviar actividad
const service = new ApiClient<UserActivityData>("/user-activity");

const useUserActivity = (property_id: string, user_id?: number) => {
  const queryClient = useQueryClient();
  const sessionKey = "session_id";

  // Generar o recuperar session_id
  let session_id = localStorage.getItem(sessionKey);
  if (!session_id) {
    session_id = uuidv4();
    localStorage.setItem(sessionKey, session_id);
  }

  const mutation = useMutation({
    mutationFn: (activityData: UserActivityData) => service.Post(activityData),
    onSuccess: () => {
      // Invalida la caché para obtener propiedades recientes
      queryClient.invalidateQueries([
        "recent-properties",
        user_id || session_id,
      ]);
    },
    onError: (error) => {
      console.error("Error al registrar actividad:", error);
    },
  });

  const registerActivity = () => {
    const activityData: UserActivityData = {
      user_id,
      property_id,
      session_id: user_id ? undefined : session_id,
      action_type: "visit",
    };

    console.log("Registrando actividad:", activityData);

    mutation.mutate(activityData);
  };

  return { registerActivity, isLoading: mutation.isLoading };
};

export default useUserActivity;
