import Label from "components/Label/Label";
import { FC, useState } from "react";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import PlacesAutoComplete from "./PlacesAutoComplete";
import {
  GoogleMap,
  Marker,
} from "@react-google-maps/api";
import useAddListingStore from "stores/addListinigStore";
import { AddressLocation } from "hooks/usePlacesAutocomplete";

export interface PageAddListing2Props {}

const PageAddListing2: FC<PageAddListing2Props> = () => {
  const [selected, setSelected] = useState<AddressLocation | null>(null);

  const {
    city,
    address,
    roomNumber,
    country,
    state,
    postal_code,
    setProperty,
    coords,
  } = useAddListingStore();

  const handleSelect = (Params: AddressLocation) => {
    setSelected(Params);
    setProperty("city", Params.city);
    setProperty("address", Params.address);
    setProperty("country", Params.country);
    setProperty("state", Params.state);
    setProperty("postal_code", Params.postal_code);
    setProperty("coords", Params.coords);
  };
    return (
      <CommonLayout
        index="02"
        nextHref="/add-listing-3"
        backtHref="/add-listing-1"
      >
        <>
          <h2 className="text-2xl font-semibold">Your place location</h2>
          <div className="w-32 border-b border-neutral-200 dark:border-neutral-700"></div>

          {/* FORM */}
          <div className="space-y-8">
            {/* ITEM */}
            <div className="h-12 bg-gray-200 animate-pulse rounded-md"></div>
            <div className="space-y-4">
              <div className="h-6 bg-gray-200 animate-pulse rounded-md w-1/3"></div>
              <div className="h-10 bg-gray-200 animate-pulse rounded-md"></div>
            </div>
            <div className="space-y-4">
              <div className="h-6 bg-gray-200 animate-pulse rounded-md w-1/3"></div>
              <div className="h-10 bg-gray-200 animate-pulse rounded-md"></div>
            </div>
            <div className="space-y-4">
              <div className="h-6 bg-gray-200 animate-pulse rounded-md w-1/3"></div>
              <div className="h-10 bg-gray-200 animate-pulse rounded-md"></div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-5">
              <div className="space-y-4">
                <div className="h-6 bg-gray-200 animate-pulse rounded-md w-1/2"></div>
                <div className="h-10 bg-gray-200 animate-pulse rounded-md"></div>
              </div>
              <div className="space-y-4">
                <div className="h-6 bg-gray-200 animate-pulse rounded-md w-1/2"></div>
                <div className="h-10 bg-gray-200 animate-pulse rounded-md"></div>
              </div>
              <div className="space-y-4">
                <div className="h-6 bg-gray-200 animate-pulse rounded-md w-1/2"></div>
                <div className="h-10 bg-gray-200 animate-pulse rounded-md"></div>
              </div>
            </div>
            <div className="space-y-4">
              <div className="h-6 bg-gray-200 animate-pulse rounded-md w-1/3"></div>
              <div className="h-10 bg-gray-200 animate-pulse rounded-md"></div>
            </div>
            <div className="mt-4">
              <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 bg-gray-200 animate-pulse rounded-xl"></div>
            </div>
          </div>
        </>
      </CommonLayout>
    );

  return (
    <CommonLayout
      index="02"
      nextHref="/add-listing-3"
      backtHref="/add-listing-1"
    >
      <>
        <h2 className="text-2xl font-semibold">Your place location</h2>
        <div className="w-32  border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <PlacesAutoComplete setSelected={handleSelect} />
          <FormItem label="Country">
            <Input
              value={country}
              onChange={(e) => setProperty("country", e.target.value)}
              placeholder="..."
            />
          </FormItem>
          {/* <FormItem label="Adree">
            <Input
              value={address}
              onChange={(e) => setProperty("address", e.target.value)}
              placeholder="..."
            />
          </FormItem> */}
          <FormItem label="Room number (optional)">
            <Input
              onChange={(e) => setProperty("roomNumber", e.target.value)}
              value={roomNumber}
            />
          </FormItem>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-5">
            <FormItem label="City">
              <Input
                onChange={(e) => setProperty("city", e.target.value)}
                value={city}
              />
            </FormItem>
            <FormItem label="State">
              <Input
                onChange={(e) => setProperty("state", e.target.value)}
                value={state}
              />
            </FormItem>
            <FormItem label="Postal code">
              <Input
                onChange={(e) => setProperty("postal_code", e.target.value)}
                value={postal_code}
              />
            </FormItem>
          </div>
          <div>
            <Label>Detailed address</Label>
            <span className="block mt-1 text-sm text-neutral-500 dark:text-neutral-400">
              {address}
            </span>
            <div className="mt-4">
              <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
                <div className="rounded-xl overflow-hidden">
                  <GoogleMap
                    zoom={10}
                    center={
                      coords
                        ? coords
                        : { lat: 20.60155293864174, lng: -105.31933848269067 }
                    }
                    mapContainerStyle={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    {selected && (
                      <Marker
                        position={
                          coords
                            ? coords
                            : {
                                lat: 20.60155293864174,
                                lng: -105.31933848269067,
                              }
                        }
                      />
                    )}
                  </GoogleMap>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing2;
