import {
  MapPinIcon,
  MagnifyingGlassIcon,
  ArrowTrendingUpIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import { locationRecommend } from "data/types";
import useNameFind from "hooks/useNameFind";
import usePlacesFind, { LocalLocation } from "hooks/usePlacesFind";
import { useRef, FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FilterStore from "stores/FilterStore";

interface Props {
  onClick?: () => void;
  onChange?: (value: string) => void;
  className?: string;
  defaultValue?: string;
  headingText?: string;
}

const LocationInput: FC<Props> = ({
  onChange = () => {},
  className = "",
  headingText = "Where to?",
}) => {
  const containerRef = useRef(null);
  const inputRef = useRef(null);
  const { value, setValue, data, isLoading } = usePlacesFind();
  const { setValue: setValueName, data: Names } = useNameFind();
  const { setFilter } = FilterStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (data && data.length === 0 && !isLoading) {
      setValueName(value);
    }
  }, [data]);

  const handleSelectLocation = async (item: LocalLocation) => {
    setValue(item.address);
    setFilter("address", item.address);
    setFilter("state", item.state);
    setFilter("city", item.city);
    setFilter("country", item.country);
    onChange(item.address);
  };

  const renderRecentSearches = () => {
    const recommendedDestinations: locationRecommend[] = [
      {
        address: "Nuevo Vallarta, Nayarit, Mexico",
        city: "Nuevo Vallarta",
        country: "Mexico",
        state: "Nayarit",
        type: "recommend",
        coords: {
          lat: 20.6969,
          lng: -105.2949,
        },
      },
      {
        address: "Punta Mita, Nayarit, Mexico",
        city: "Punta Mita",
        country: "Mexico",
        state: "Nayarit",
        type: "recommend",
        coords: {
          lat: 20.7714,
          lng: -105.5185,
        },
      },
      {
        address: "Sayulita, Nayarit, Mexico",
        city: "Sayulita",
        country: "Mexico",
        state: "Nayarit",
        type: "recommend",
        coords: {
          lat: 20.8686,
          lng: -105.4404,
        },
      },
      {
        address: "San Pancho, Nayarit, Mexico",
        city: "San Pancho",
        country: "Mexico",
        state: "Nayarit",
        type: "recommend",
        coords: {
          lat: 20.9016,
          lng: -105.4184,
        },
      },
      {
        address: "Careyes, Jalisco, Mexico",
        city: "Careyes",
        country: "Mexico",
        state: "Jalisco",
        type: "recommend",
        coords: {
          lat: 19.4683,
          lng: -105.0444,
        },
      },
    ];

    const recentSearches: locationRecommend[] = JSON.parse(
      localStorage.getItem("recentSearches") || "[]"
    ).map((search: LocalLocation) => ({
      address: search.address,
      city: search.city,
      state: search.state,
      country: search.country,
      coords: search.coords,
      type: "recent",
    }));

    const filteredRecommended = recommendedDestinations.filter(
      (item) =>
        !recentSearches.some(
          (search) =>
            search.city === item.city ||
            search.address.split(",")[0] === item.address.split(",")[0]
        )
    );

    const combinedDestinations = [
      ...recentSearches,
      ...filteredRecommended.filter((item) => !recentSearches.includes(item)),
    ].slice(0, 5);

    return (
      <>
        <p className="block font-semibold text-base">Find Your Destination</p>
        <div className="mt-3">
          {combinedDestinations.map((item) => {
            return (
              <div
                className="py-2 mb-1 flex items-center space-x-3 text-sm"
                onClick={() => handleSelectLocation(item)}
                key={item.address}
              >
                {item.type === "recommend" ? (
                  <ArrowTrendingUpIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                ) : (
                  <ClockIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                )}
                <span className="">{item.address}</span>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const renderSearchValues = ({
    heading,
    items,
  }: {
    heading: string;
    items: LocalLocation[];
  }) => {
    return (
      <>
        <p className="block font-semibold text-base">
          {heading || "Destinations"}
        </p>
        <div className="mt-3">
          {items.map((item) => {
            return (
              <div
                className="py-2 mb-1 flex items-center space-x-3 text-sm"
                onClick={() =>
                  handleSelectLocation({
                    address: item.address,
                    city: item.city,
                    coords: item.coords,
                    country: item.country,
                    state: item.state,
                  })
                }
                key={item.address}
              >
                <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                <span className="">{`${item.address}`}</span>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const renderSearchNames = () => {
    return (
      <>
        <p className="block font-semibold text-base">House Names</p>
        <div className="mt-3">
          {Names &&
            Names.map((name) => {
              return (
                <div
                  className="py-2 mb-1 flex items-center space-x-3 text-sm"
                  onClick={() =>
                    navigate(
                      `/listing-stay-detail/${name.title}/${name.property_id}`
                    )
                  }
                  key={name.property_id}
                >
                  <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                  <span className="">{`${name.title}`}</span>
                </div>
              );
            })}
        </div>
      </>
    );
  };

  return (
    <div className={`${className}`} ref={containerRef}>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {headingText}
        </span>
        <div className="relative mt-5">
          <input
            className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
            placeholder={"Search destinations"}
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
            ref={inputRef}
          />
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
          </span>
        </div>
        <div className="mt-7">
          {value ? (
            <>
              {data && data.length > 0
                ? renderSearchValues({
                    heading: "Locations",
                    items: data ? data : [],
                  })
                : renderSearchNames()}
            </>
          ) : (
            renderRecentSearches()
          )}
        </div>
      </div>
    </div>
  );
};

export default LocationInput;
