import { useQuery } from "@tanstack/react-query";
import { StayDataType } from "data/types";
import ApiClient from "services/apiClient";
import useUserStore from "stores/userStore";

const service = new ApiClient<StayDataType[]>("/user-activity/recent");

const useRecentProperties = () => {
  const { user } = useUserStore();
  const session_id = localStorage.getItem("session_id");

  // Consulta para obtener propiedades recientes (autenticado o no)
  const fetchRecentProperties = async (): Promise<StayDataType[]> => {
    return service.getAllWithParams({
      params: {
        user_id: user?.user_id,
        session_id: user?.user_id ? undefined : session_id,
      },
    });
  };

  return useQuery({
    queryKey: ["recent-properties", user?.user_id || session_id],
    queryFn: fetchRecentProperties,
    enabled: !!(user?.user_id || session_id),
    staleTime: 1000 * 60 * 5,
    keepPreviousData: true,
  });
};

export default useRecentProperties;
