import React, { useState } from "react";
import ModalReserveMobile from "./ModalReserveMobile";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import useFilterStore from "stores/FilterStore";
import { useParams } from "react-router-dom";
import useProperty from "hooks/properties/useProperty";
import getPricePerNight from "utils/GetCurrentPrice";
import { FaPhoneAlt } from "react-icons/fa";
import ModalContactMobile from "./ModalContactMobile";

const MobileFooterSticky = () => {
  const { check_in, check_out, setFilter } = useFilterStore();

  const [startDate, setStartDate] = useState<Date | null>(
    check_in ? check_in : new Date()
  );
  const [endDate, setEndDate] = useState<Date | null>(
    check_out ? check_out : new Date()
  );

  // get id from url
  const { id } = useParams();
  const { data: Property, isLoading, error } = useProperty(id!);

  const handleSelectDate = (startDate: Date | null, endDate: Date | null) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setFilter("check_in", startDate);
    setFilter("check_out", endDate);
  };

  if (isLoading) return <div>Loading...</div>;

  if (error) return <div>Something went wrong: {error.message}</div>;

  const renderRentFooter = () => {
    return (
      <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-40">
        <div className="container flex items-center justify-between">
          <div className="">
            <span className="block text-xl font-semibold">
              {
                getPricePerNight(
                  Property.rates,
                  check_in ? new Date(check_in) : new Date(),
                  check_out ? new Date(check_out) : new Date()
                ).Text
              }
              <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
                /night
              </span>
            </span>
            <ModalSelectDate
              handleSelectDate={handleSelectDate}
              renderChildren={({ openModal }) => (
                <span
                  onClick={openModal}
                  className="block text-sm underline font-medium"
                >
                  {converSelectedDateToString([startDate, endDate])}
                </span>
              )}
            />
          </div>
          <ModalReserveMobile
            property_id={id}
            renderChildren={({ openModal }) => (
              <ButtonPrimary
                sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
                onClick={openModal}
              >
                Reserve
              </ButtonPrimary>
            )}
          />
        </div>
      </div>
    );
  };

  const renderSellFooter = () => {
    return (
      <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-40">
        <div className="container flex items-center justify-between">
          {/* Call Button */}
          <div className="flex items-center">
            <a
              href={`tel:3222302020`}
              className="block text-sm font-medium border rounded-xl p-3 rounded-full shadow-lg hover:bg-primary-600 transition-colors"
            >
              <FaPhoneAlt />
            </a>
            <span className="text-sm text-neutral-500 dark:text-neutral-400"></span>
          </div>
          {/* whatsapp button */}
          <div className="flex items-center">
            <a
              href={`https://wa.me/523222302020?text=Hello!%20I'd%20like%20to%20be%20contacted%20regarding%20the%20property%20for%20sale%20that%20I%20saw%20${window.location.href}`}
              target="_blank"
              rel="noreferrer"
              className="block text-sm font-medium bg-green-500 text-white rounded-xl p-3 shadow-lg hover:bg-green-600 transition-colors"
            >
              <span className="mr-1">Whatsapp</span>
            </a>
            <span className="text-sm text-neutral-500 dark:text-neutral-400"></span>
          </div>
          <ModalContactMobile
            renderChildren={({ openModal }) => (
              <ButtonPrimary
                sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
                onClick={openModal}
              >
                Contact Us
              </ButtonPrimary>
            )}
          />
        </div>
      </div>
    );
  };
  return <>{Property.type === 0 ? renderRentFooter() : renderSellFooter()}</>;
};

export default MobileFooterSticky;
