export const initGooglePlaces = (apiKey: string, callback?: () => void) => {
  // Evita cargar el script si ya está presente
  if (document.querySelector(`script[src*="maps.googleapis.com"]`)) {
    console.warn("Google Maps ya está cargado.");
    callback?.();
    return;
  }

  const script = document.createElement("script");
  script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
  script.async = true;
  script.defer = true;

  script.onload = () => {
    console.log("Google Maps cargado correctamente.");
    callback?.();
  };

  script.onerror = () => {
    console.error("Error al cargar Google Maps.");
  };

  document.head.appendChild(script);
};