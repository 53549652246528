import { FC, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import Heading from "components/Heading/Heading";
import { StayDataType, TaxonomyType } from "data/types";
import StayCard from "components/StayCard/StayCard";
import { PropertiesResponse } from "hooks/properties/useGetRecommendedProperties";

export interface SectionRecommendedStaysProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  categories?: TaxonomyType[];
  itemPerRow?: 4 | 5;
  uniqueClassName: string;
  data: PropertiesResponse;
}

const SectionRecommendedStays: FC<SectionRecommendedStaysProps> = ({
  heading = "Based on Your Last Search",
  subHeading = "Rentals that match your preferences.",
  className = "",
  itemPerRow = 4,
  uniqueClassName,
  data,
}) => {
  const renderCard = (stay: StayDataType) => {
    return <StayCard key={stay.property_id} data={stay} />;
  };

  const NoPropertyAvailable = () => {
    return (
      <div>
        <span className="text-gray-500">
          No properties were found with those filters.
        </span>
      </div>
    );
  };

  return (
    <div className={`nc-SectionSliderNewCategories ${className}`}>
      <div className="flow-root">
        <Heading desc={subHeading}>{heading}</Heading>

        <Swiper
          spaceBetween={20}
          slidesPerView={itemPerRow}
          pagination={{
            clickable: true,
            el: `.swiper-pagination-${uniqueClassName}`,
          }}
          autoplay={{
            delay: 5000, // Se mueve cada 5 segundos
            disableOnInteraction: false, // No se detiene si el usuario interactúa
          }}
          modules={[Pagination, Autoplay]}
          breakpoints={{
            1280: {
              slidesPerView: itemPerRow,
            },
            1024: {
              slidesPerView: itemPerRow,
            },
            768: {
              slidesPerView: itemPerRow - 2,
            },
            640: {
              slidesPerView: 1.5,
            },
            500: {
              slidesPerView: 1.2,
            },
            375: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
          }}
        >
          {data && data.results.length === 0 ? (
            <NoPropertyAvailable />
          ) : (
            data?.results.map((stay) => (
              <SwiperSlide key={stay.property_id}>
                {renderCard(stay)}
              </SwiperSlide>
            ))
          )}
        </Swiper>

        {/* Paginación (dots) personalizada */}
        <div
          className={`swiper-pagination-${uniqueClassName}`}
          style={{
            marginTop: "15px",
            textAlign: "center",
            paddingBottom: "10px",
          }}
        />
      </div>
    </div>
  );
};

export default SectionRecommendedStays;
