import create from "zustand";

interface GeneralStore {
  openLocationModal: boolean;
  setOpenLocationModal: (isOpen: boolean) => void;
}

const useGeneralStore = create<GeneralStore>((set) => ({
  openLocationModal: false,
  setOpenLocationModal: (isOpen: boolean) => set({ openLocationModal: isOpen }),
}));

export default useGeneralStore;
