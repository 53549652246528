import { useMutation, useQueryClient } from "@tanstack/react-query";
import userService, { UserData } from "services/UserService";
import useUserStore from "stores/userStore";

const useSignUp = (onAdd: () => void) => {
  const queryClient = useQueryClient();
  const { setUser, setIsLogged } = useUserStore();
  return useMutation({
    mutationFn: (newUser: UserData) => userService.Post(newUser),
    onSuccess: (savedUser, _newUser) => {
      queryClient.setQueryData<UserData>(["user"], savedUser);
      const { token, ...userData } = savedUser;
      setUser(userData);
      setIsLogged(true);
      console.log(savedUser);
      localStorage.setItem("jwt", token);
      onAdd();
    },
    onError: (error) => {
      console.error(error);
    },
  });
};

export default useSignUp;
