import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
import SectionVideos from "./SectionVideos";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BuceriasImg from "images/zones/Bucerias.jpg";
import PuntaMitaImg from "images/zones/puntaMita.jpg";
import ConchasChinasImg from "images/zones/conchasChina.jpg";
import MarinaVallartaImg from "images/zones/Marina.jpg";
import CruzDeHuanacaxtleImg from "images/zones/cruzHuanacaxtle.jpg";
import NuevoVallartaImg from "images/zones/nuevoVallarta.jpg";
import SayulitaImg from "images/zones/Sayulita.jpg";
import ZonaHoteleraImg from "images/zones/zonaHotelera.jpg";
import AmapasImg from "images/zones/Ampas.jpg";
import Arcos from "images/places/Scuba Diving in Puerto Vallarta.jpeg";
import colomitos from "images/places/colomitos.jpeg";
import islas from "images/places/islasMari.jpeg";
import malecon from "images/places/puntaMita.jpg";
import SectionRecentlyViewed from "components/SectionRecentlyViewed/SectionRecentlyViewed";
import SectionRecommendedStays from "components/SectionRecommendedStays/SectionRecommendedStays";
import useRecentProperties from "hooks/users/useRecentProperties";
import useGetRecommendedProperties from "hooks/properties/useGetRecommendedProperties";

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "/listing-stay?city=Bucerias",
    name: "Bucerias",
    taxonomy: "category",
    count: 188288,
    thumbnail: BuceriasImg,
  },
  {
    id: "2",
    href: "/listing-stay?city=Punta+Mita",
    name: "Punta De Mita",
    taxonomy: "category",
    count: 188288,
    thumbnail: PuntaMitaImg,
  },
  {
    id: "3",
    href: "/listing-stay?zone=Conchas+Chinas",
    name: "Conchas Chinas",
    taxonomy: "category",
    count: 188288,
    thumbnail: ConchasChinasImg,
  },
  {
    id: "4",
    href: "/listing-stay?zone=Marina+Vallarta",
    name: "Marina Vallarta",
    taxonomy: "category",
    count: 188288,
    thumbnail: MarinaVallartaImg,
  },
  {
    id: "5",
    href: "/listing-stay?city=Cruz+de+Huanacaxtle",
    name: "Cruz De Huanacaxtle",
    taxonomy: "category",
    count: 188288,
    thumbnail: CruzDeHuanacaxtleImg,
  },
  {
    id: "6",
    href: "/listing-stay?city=Nuevo+Vallarta",
    name: "Nuevo Vallarta",
    taxonomy: "category",
    count: 188288,
    thumbnail: NuevoVallartaImg,
  },
  {
    id: "7",
    href: "/listing-stay?city=Sayulita",
    name: "Sayulita",
    taxonomy: "category",
    count: 188288,
    thumbnail: SayulitaImg,
  },
  {
    id: "8",
    href: "/listing-stay?zone=Zona+Hotelera",
    name: "Zona Hotelera",
    taxonomy: "category",
    count: 188288,
    thumbnail: ZonaHoteleraImg,
  },
  {
    id: "9",
    href: "/listing-stay?zone=Amapas",
    name: "Amapas",
    taxonomy: "category",
    count: 188288,
    thumbnail: AmapasImg,
  },
];

const DEMO_CATS_2: TaxonomyType[] = [
  {
    id: "0",
    href: "/blog-single",
    name: "Arcos de Puerto Vallarta",
    taxonomy: "category",
    count: 188288,
    thumbnail: Arcos,
  },
  {
    id: "1",
    href: "/blog-single",
    name: "Colomitos",
    taxonomy: "category",
    count: 188288,
    thumbnail: colomitos,
  },
  {
    id: "2",
    href: "/blog-single",
    name: "Islas Marietas",
    taxonomy: "category",
    count: 188288,
    thumbnail: islas,
  },
  {
    id: "3",
    href: "/blog-single",
    name: "Punta de Mita",
    taxonomy: "category",
    count: 188288,
    thumbnail: malecon,
  },
];

function PageHome() {
  const { data } = useRecentProperties();
  const { data: RecommendedProperties } = useGetRecommendedProperties(1, 12);
  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        {/* SECTION HERO */}
        <SectionHero className="pt-10 lg:pt-16 lg:pb-16" />

        {/* RECENTLY VIEWED SECTION*/}
        {data && data.length != 0 && (
          <div className="relative py-16">
            <BackgroundSection />
            <SectionRecentlyViewed
              data={data}
              uniqueClassName="RecentlyViewedSection"
            />
          </div>
        )}

        {/* OUR PVR PLACES SECTION*/}
        <SectionSliderNewCategories
          categories={DEMO_CATS}
          uniqueClassName="PageHome_s1"
        />

        {/* RECOMMENDATIONS BASED UN SEARCH */}
        {RecommendedProperties && RecommendedProperties.results.length != 0 && (
          <div className="relative py-16">
            <BackgroundSection />
            <SectionRecommendedStays
              data={RecommendedProperties}
              uniqueClassName="RecommendedStays"
            />
          </div>
        )}

        {/* PVR RECOMMENDS GENERAL */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturePlaces />
        </div>

        {/* SECTION 1 */}
        <SectionSliderNewCategories
          heading="Explore by types of stays"
          subHeading="Explore houses based on 10 types of stays"
          categoryCardType="card5"
          itemPerRow={5}
          uniqueClassName="PageHome_s3"
        />

        {/* SECTION 1 */}
        <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionSliderNewCategories
            categories={DEMO_CATS_2}
            categoryCardType="card4"
            itemPerRow={4}
            heading="Suggestions for discovery"
            subHeading="Popular places to stay that PVR PROPERTIES recommends for you"
            sliderStyle="style2"
            uniqueClassName="PageHome_s2"
          />
        </div>

        {/* SECTION */}
        <SectionSubscribe2 />

        {/* SECTION */}
        {/* <SectionGridCategoryBox /> */}
        {/* SECTION */}
        <SectionVideos />

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageHome_" />
        </div>
      </div>
    </div>
  );
}

export default PageHome;
