import { useMutation, useQueryClient } from "@tanstack/react-query";
import ApiClient from "services/apiClient";

interface ReservationResponse {
  message: string;
  payment_intent_id?: string;
  client_secret?: string;
  reservation_id: number;
}

export interface CreateReservationParams {
  user_id: number;
  property_id: number;
  start_date: string;
  end_date: string;
  guests: number;
  infants: number;
  children: number;
  total_price: number;
  payment_type: "full" | "partial";
  currency: string;
  user_name: string;
  email: string;
  property_name: string;
}

export interface ConfirmReservationParams {
  reservation_id: number;
  is_available: boolean;
}

const apiService = new ApiClient<CreateReservationParams>(
  "/create-checkout-session"
);
const confirmService = new ApiClient<ConfirmReservationParams>("/checkout");
export const useReservation = (
  onSuccess: (data: ReservationResponse) => void,
  onError: (error: Error) => void
) => {
  const queryClient = useQueryClient();

  const createReservation = useMutation<
    ReservationResponse,
    Error,
    CreateReservationParams
  >({
    mutationFn: (data) =>
      apiService.PostWithResponse<ReservationResponse>(data),
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (error, _newReservation, context) => {
      onError(error as Error);
    },
  });

  const confirmReservation = useMutation<void, Error, ConfirmReservationParams>(
    {
      mutationFn: (data) => confirmService.PostWithResponse<void>(data),
      onMutate: async (data) => {
        const previousReservations = queryClient.getQueryData<
          ReservationResponse[]
        >(["Reservations"]);

        queryClient.setQueryData<ReservationResponse[]>(
          ["Reservations"],
          (reservations) =>
            reservations?.map((reservation) =>
              reservation.reservation_id === data.reservation_id
                ? { ...reservation, message: "Confirming reservation..." }
                : reservation
            ) || []
        );
        return { previousReservations };
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(["Reservations"]);
        onSuccess({ message: "Reservation confirmed", reservation_id: 0 });
      },
      onError: (error, _confirmReservation, context) => {
        onError(error);
      },
    }
  );

  return { createReservation, confirmReservation };
};

export default useReservation;
